import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';

import Container from 'components/Container';
import FileLogo from 'images/logo.svg';
import FileHoverHome from 'images/hover-home.svg';
import FileHoverServices from 'images/hover-services.svg';
import FileHoverFaq from 'images/hover-faq.svg';
import FileHoverContact from 'images/hover-contact.svg';

import {
  Wrapper,
  Navbar,
  Menu,
  MenuItem,
  MenuHover,
  Circle,
  Heading,
  CallButton,
  HeadingWrapper,
} from './styled';

const Header = ({ siteTitle, isHome, isMenuOpen, setMenuOpen }) => {
  return (
    <Wrapper open={isMenuOpen}>
      <Container>
        <Navbar>
          <Link to="/">
            <img src={FileLogo} alt={siteTitle} />
          </Link>

          <Menu open={isMenuOpen}>
            <MenuItem to="/">
              Home
              <MenuHover src={FileHoverHome} />
            </MenuItem>
            <MenuItem to="/services">
              Services &amp; Pricing
              <MenuHover src={FileHoverServices} />
            </MenuItem>
            <MenuItem to="/faq">
              FAQ
              <MenuHover src={FileHoverFaq} />
            </MenuItem>
            <MenuItem to="/contact">
              Contact
              <MenuHover src={FileHoverContact} />
            </MenuItem>
          </Menu>
        </Navbar>

        {isHome && (
          <Row>
            <Col lg={6} lgOffset={3}>
              <HeadingWrapper>
                <Heading>
                  Let us bring a clean happiness into your busy life
                </Heading>
                <CallButton href="tel:13475498326">1-347-549-8326</CallButton>
              </HeadingWrapper>
            </Col>
          </Row>
        )}
      </Container>

      <Circle />
    </Wrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  isHome: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: ``,
  isHome: false,
};

export default Header;
