import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'themes';

export const Wrapper = styled.header`
  background: #f3f8ff;
  padding: 30px 0;
  overflow: hidden;

  ${media.md`
    ${({ open }) =>
      open &&
      `
      position: relative;
      z-index: 999;
    `}
  `}

  ${media.sm`
    background: #FFFFFF;
  `}

  ${({ open }) => open && `
    position: fixed !important;
    width: 100vw;
    height: 100vh;
  `}
`;

export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.sm`
    padding: 0 16px;
  `}
`;

export const Menu = styled.div`
  ${media.md`
    display: none;
    align-items: center;
    justify-content: center
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    z-index: 999;

    ${({ open }) =>
      open &&
      `
      display: flex;
    `}
  `}
`;

export const MenuItem = styled(Link).attrs({
  activeClassName: 'active',
})`
  position: relative;
  color: #474c57;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;

  ${media.md`
    font-size: 36px;
    line-height: auto;
  `}

  ${media.sm`
    font-size: 28px;
    line-height: auto;
  `}

  &.active {
    ${media.md`
      color: #75a605;
    `}

    img {
      opacity: 1;

      ${media.md`
        opacity: 0;
      `}
    }
  }

  & + & {
    margin-left: 85px;

    ${media.md`
      margin-left: 0;
      margin-top: 50px;
    `}
  }
`;

export const MenuHover = styled.img`
  position: absolute;
  bottom: -12px;
  left: 0;
  opacity: 0;
`;

export const Circle = styled.div`
  position: absolute;
  left: 50%;
  top: -300px;
  transform: translateX(-50%);
  width: 1016px;
  height: 1016px;
  background-color: #ffffff;
  border-radius: 50%;
  z-index: 0;

  ${media.md`
    width: 624px;
    height: 624px;
    top: -100px;
  `}

  ${media.sm`
    width: 320px;
    height: 320px;
    top: -200px;
    background-color: #F3F8FF;
  `}
`;

export const HeadingWrapper = styled.div`
  margin-top: 55px;
  text-align: center;
`;

export const Heading = styled.h1`
  color: #474c57;
  font-size: 32px;
  line-height: 40px;

  ${media.md`
    padding: 0 100px;
  `}

  ${media.sm`
    font-size: 24px;
    line-height: 32px;
    padding: 0 40px;
  `}
`;

export const CallButton = styled.a`
  display: inline-block;
  color: #75a605;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  padding: 10px 40px;
  border: 2px dashed rgb(117, 166, 5, 0.2);
  border-radius: 30.5px;
  margin-top: 32px;
  margin-bottom: 120px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${media.sm`
    margin-bottom: 50px;
  `}
`;
