import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'themes';

export const Wrapper = styled.footer`
  position: relative;
  z-index: 1;
  background-color: #f8f8f8;
  padding-top: 110px;

  ${media.md`
    padding-top: 50px;
  `}

  ${media.sm`
    padding: 40px 0 0 0;
  `}
`;

export const ContactDetails = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 28px;
`;

export const Label = styled.div`
  color: #ababab;
`;

export const Value = styled.div`
  color: #5a5f6a;
`;

export const Navigation = styled.nav``;

export const Item = styled(Link).attrs({
  activeClassName: 'active',
})`
  color: #ababab;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  & + & {
    margin-left: 90px;
  }

  &.active {
    color: #5a5f6a;
  }
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  a + a {
    margin-left: 30px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #ababab;
  margin-top: 90px;
  position: relative;
  padding-bottom: 45px;

  ${media.md`
    display: block;
  `}
`;

export const Copyright = styled.div``;

export const Author = styled.div`
  text-align: right;

  ${media.md`
    text-align: left;
  `}

  a {
    color: #5a5f6a;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Flower = styled.img`
  position: absolute;
  bottom: 0;
  left: 300px;

  ${media.sm`
    display: none;
  `}
`;

export const ContactWrapper = styled.div`
  ${media.md`
    display: flex;

    & > div {
      margin-right: 70px;
    }
  `}

  ${media.sm`
    display: block;
  `}
`;