import React, { Component } from 'react';

import { emailRegexp } from './constants';
import { Wrapper, Info, InputRow, Input, Submit } from './styled';

class Newsletter extends Component {
  state = {
    email: '',
    submitted: false,
    error: false,
  };

  handleEmailChange = ({ target }) => {
    this.setState({
      email: target.value,
    });
  };

  isValidEmail = email => emailRegexp.test(String(email).toLowerCase());

  render() {
    const { email } = this.state;

    return (
      <Wrapper
        action="https://joannacleaningservice.us20.list-manage.com/subscribe/post?u=f9b44bb4c19dca938f9009848&amp;id=5ab12d8b9a"
        method="post"
        target="_blank"
        name="mc-embedded-subscribe-form"
      >
        <Info>Signup to newsletter! Get $10 off your first cleaning!</Info>

        <InputRow>
          <Input
            type="text"
            name="EMAIL"
            placeholder="Your e-mail address..."
            value={email}
            onChange={this.handleEmailChange}
          />
          <Submit
            disabled={!this.isValidEmail(email) || this.state.submitting}
            type="submit"
          >
            {this.state.submitting ? 'Saving' : 'Sign Up'}
          </Submit>
        </InputRow>
      </Wrapper>
    );
  }
}

export default Newsletter;
