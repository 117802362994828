import { css } from 'styled-components';

const sizes = {
  lg: 1200,
  md: 992,
  sm: 767,
  xs: 576
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16;

  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const isDesktop = typeof window !== 'undefined' && window.innerWidth > sizes.md;
export const isMobile = typeof window !== 'undefined' && window.innerWidth <= sizes.sm;
export const isTablet = typeof window !== 'undefined' && window.innerWidth <= sizes.md;
