import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import FileInstagram from 'images/instagram.svg';
import FileYelp from 'images/yelp-logo-grey.svg';
import FileFlower from 'images/flower.svg';
import Container from 'components/Container';

import Newsletter from './components/Newsletter';
import {
  Wrapper,
  ContactDetails,
  Label,
  Value,
  Navigation,
  Item,
  Social,
  Bottom,
  Copyright,
  Author,
  Flower,
  ContactWrapper,
} from './styled';

const Footer = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col lg={9} hiddenLgDown>
          <Navigation>
            <Item to="/">Home</Item>
            <Item to="/services">Services &amp; Pricing</Item>
            <Item to="/faq">FAQ</Item>
            <Item to="/contact">Contact</Item>
          </Navigation>

          <Newsletter />
        </Col>
        <Col lg={3}>
          <ContactWrapper>
            <ContactDetails>
              <Value>
                Joanna Cleaning Service
                <br />
                11235 Brooklyn, NY
              </Value>
            </ContactDetails>
            <div>
              <ContactDetails>
                <Label>Telephone:</Label>
                <Value>+1 347 549 8326</Value>
              </ContactDetails>
              <ContactDetails>
                <Label>E-mail:</Label>
                <Value>joannacleaningserviceny@gmail.com</Value>
              </ContactDetails>
            </div>
          </ContactWrapper>
        </Col>
        <Col hiddenLgUp>
          <Newsletter />
        </Col>
      </Row>

      <Social>
        <a href="https://www.yelp.com/biz/joanna-cleaning-service-brooklyn-2" target="_blank" rel="noopener noreferrer">
          <img src={FileYelp} alt="Yelp" />
        </a>
        <a href="https://www.instagram.com/joanna_cleaning_service_/" target="_blank" rel="noopener noreferrer">
          <img src={FileInstagram} alt="Instagram" />
        </a>
      </Social>

      <Bottom>
        <Copyright>All rights reserved - Joanna Cleaning Service</Copyright>
        <Author>
          Crafted by{' '}
          <a href="https://www.innovatica.com/" target="_blank" rel="noopener noreferrer">
            Innovatica
          </a>
        </Author>

        <Flower src={FileFlower} />
      </Bottom>
    </Container>
  </Wrapper>
);

export default Footer;
