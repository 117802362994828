import styled from 'styled-components';
import { media } from 'themes';

export const Wrapper = styled.form`
  display: inline-block;
  margin-top: 100px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 16px 26px;

  ${media.md`
    margin-top: 60px;
  `}

  ${media.sm`
    display: block;
    margin-top: 20px;
  `}
`;

export const Info = styled.div`
  color: #75a605;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
`;

export const InputRow = styled.div`
  margin-top: 8px;

  ${media.sm`
    margin-top: 16px;
    text-align: right;
  `}
`;

export const Input = styled.input`
  height: 40px;
  width: 300px;
  border: 1px solid #eaeaea;
  border-radius: 24px;
  background-color: #ffffff;
  padding: 0 24px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 28px;
  color: #5a5f6a;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: #ababab;
  }

  ${media.sm`
    width: 100%;
  `}
`;

export const Submit = styled.button`
  margin-left: 16px;
  height: 40px;
  width: 121px;
  border-radius: 30.5px;
  border: 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: #75a605;
  color: #ffffff;
  transition: color 200ms, background-color 200ms;

  &:disabled {
    background-color: #f0f0f0;
    color: #ababab;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    &:not(:disabled) {
      cursor: pointer; 
      text-decoration: underline;
    }
  }

  ${media.sm`
    display: inline-block;
    margin-left: auto;
    margin-top: 16px;
  `}
`;

export const Message = styled.div`
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #75a605;

  ${({ error }) => error && `
    color: red;
  `}
`;
