import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { BaseCSS } from 'styled-bootstrap-grid';
import { Slider } from 'react-burgers'

import Header from 'components/Header';
import Footer from 'components/Footer';
import GlobalStyles from 'themes/global';

import { Content, Burger } from './styled';

const Layout = ({ children, isHome }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <BaseCSS />
          <GlobalStyles />

          <Burger onClick={() => setMenuOpen(!isMenuOpen)}>
            <Slider
              width={18}
              lineHeight={2}
              lineSpacing={4}
              padding={0}
              color="#FFFFFF"
              active={isMenuOpen}
            />
          </Burger>

          <Header
            siteTitle={data.site.siteMetadata.title}
            isHome={isHome}
            isMenuOpen={isMenuOpen}
            setMenuOpen={setMenuOpen}
          />
          <Content isMenuOpen={isMenuOpen}>{children}</Content>
          <Footer />
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
};

export default Layout;
