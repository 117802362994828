import styled from 'styled-components';
import { media } from 'themes';

export const Content = styled.div`
  position: relative;
  z-index: 2;

  ${({ isMenuOpen }) => isMenuOpen && `
    z-index: -1;
  `}
`;

export const Burger = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 48px;
  right: 48px;
  z-index: 9999;
  height: 48px;
  width: 48px;
  background-color: #75a605;
  box-shadow: 0 4px 8px -2px rgba(117, 166, 5, 0.7);
  border-radius: 50%;

  ${media.md`
    display: flex;
  `}

  ${media.sm`
    top: 35px;
    right: 16px;
  `}
`;
