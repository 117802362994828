import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import { media } from 'themes';

const StyledRow = styled(Container)`
  position: relative;
  padding: 0;
  z-index: 1;

  ${media.sm`
    padding-left: 15px;
    padding-right: 15px;
  `}
`;

export default StyledRow;
